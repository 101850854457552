<template>
  <div class="todo-app-list">
    <ekle-headers
      :title="'Güzergah Bilgisi'"
      :baslik="form.urun_adi"
      @mqShallShowLeftSidebar="$emit('mqShallShowLeftSidebar', $event)"
      @removeProduct="$emit('removeProduct', $event)"
    />
    <vue-perfect-scrollbar :settings="scrollbarSettings" class="todo-task-list-wrapper scroll-area p-2">
      <b-card-normal :title="'Ürün güzergah bilgisi.'">
        <template v-slot:body>
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm" autocomplete="off">
              <b-row>
                <b-col>
                  <b-form-group label="Güzergah kodu giriniz">
                    <b-form-textarea class="rounded-0" ref="on_aciklama" v-model="form.guzergah_kodu" />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <div class="d-flex justify-content-between">
                    <h5>Harita görünümü</h5>
                    <b-link @click="redirectToMaps"> Yeni Güzergah oluşturun</b-link>
                  </div>
                </b-col>
                <b-col>
                  <div v-html="form.guzergah_kodu"></div>
                </b-col>
              </b-row>
              <b-row class="pt-1">
                <b-col cols="12" md="6" lg="6">
                  <b-button squared block type="submit" variant="primary">
                    <feather-icon icon="CheckSquareIcon" class="mr-50" />
                    <span class="align-middle">{{ form._id == null ? 'Kaydet ve Devam et' : 'Güncelle' }}</span>
                  </b-button>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <b-button squared block>
                    <feather-icon icon="XSquareIcon" class="mr-50" />
                    <span class="align-middle">İptal</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </template>
      </b-card-normal>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import EkleHeaders from '../component/ekleHeader.vue';
import BCardNormal from '@core/components/b-card-actions/BCardNormal.vue';
export default {
  components: {
    VuePerfectScrollbar,
    EkleHeaders,
    BCardNormal,
  },
  props: {
    form: {
      type: Object,
      required: true,
      default: {},
    },
    scrollbarSettings: {
      type: Object,
      required: true,
    },
  },
  computed: {},
  methods: {
    redirectToMaps() {
      window.open(
        'https://www.google.com/maps/d/u/0/viewer?mid=1DoMBHALXxGRhUwGwRZyY9clSfJH2uj_X&ll=36.86248297251583%2C30.98360000000002&z=10',
        '_blank'
      );
    },
    onSubmit() {
      this.$emit('onSubmit');
    },
  },
};
</script>
<style lang="scss"></style>
